/* --------------------style.css---------------------- */

/********COMMON********/
.new_con_tick {
  font-family: 'SansRegular';
  font-size: 11px;
  color: var(--light-1);
  font-weight: 100;
}
.text-shadow {
  color: #fff !important;
  text-shadow: 2px 2px 5px black !important;
}
.desktop_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.btn_pop_spacing {
  margin: 40px 0px;
}
.spacing_pop {
  margin-bottom: 28px;
}
.final_hov:hover {
  background-color: var(--primary);
}
.align-cen {
  display: grid;
  justify-content: center;
}
.back_img_gold {
  background-image: url('../pictures/Diamond.png');
  background-repeat: no-repeat;
  background-position: right;
}
.svg_ticket {
  margin-right: 5px;
  margin-top: 3px;
}
.final_svg_top {
  display: flex;
  font-family: 'SansSemiBold';
}
.ticket-btn-gold {
  color: #c09e3f !important;
  width: 20vw;
  /* padding: 0 30px; */
  height: 45px;
  background: var(--light-5);
  border-radius: 5px;
  /*margin-left: auto;*/
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  /*text-transform: uppercase;*/
  position: relative;
  margin-bottom: 20px;
  margin-left: auto;
  outline: 0;
  border: 1px solid transparent;
}
.ticket-btn-gold::after {
  content: none !important;
}
.ticket-btn-gold:hover {
  border: 1px solid #c09e3f !important;
}
.final_btn_main_golden {
  background-color: #c09e3f !important;
  color: white !important;
  border: 1px solid #c09e3f !important;
}
.final_btn_main_golden:hover {
  background-color: white !important;
  color: #c09e3f !important;
  border: 1px solid #c09e3f !important;
}
.golden_content_final {
  color: black !important;
}
.heading_golden_final {
  color: #fff !important;
}
.gold_final_text {
  color: #c09e3f !important;
}
.back_gold_final {
  background-color: #c09e3f !important;
}
.special_final {
  white-space: nowrap;
}
.Inc_final {
  font-size: 12px;
  font-weight: 400;
}
.final_image {
  margin-right: 15px;
}
.use_bar {
  font-size: 10px;
  font-weight: 400;
  line-height: 2rem;
}
.final_btn_main {
  height: 45px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 600;
  padding: 0 2rem;
  background-color: var(--cinerji-Pantone-Blush);
  /* background-color: #1f1c3c; */
  color: #fff;
  border: none;
  outline: 0;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s, transform 0.25s;
  border: 1px solid #1f1c3c;
  margin: 20px auto;
}
.final_btn_main:hover {
  background-color: white;
  color: var(--cinerji-Pantone-Burgandy);
  /* color: #1f1c3c; */
  border: 1px solid #1f1c3c;
}
.text-primary {
  /* color: var(--primary) !important; */
  color: #1f1c3c;
}

.text-golden {
  color: var(--yellow-shade) !important;
}

.text-grey {
  color: var(--light-1);
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.otp-input input[type='number']::-webkit-inner-spin-button,
.otp-input input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.otp-input input[type='number'] {
  -moz-appearance: textfield;
}

.otp-input input {
  padding: 0 15px;
  width: 100% !important;

  /* text-align: center;
  width: 56px !important; */
  height: 56px;
  background: transparent;
  border: 1px solid var(--light-1);
  margin-right: 10px;
  border-radius: 12px;
  color: var(--light-5);
}

.custom-upload label {
  cursor: pointer;
}

.custom-upload input[type='file'] {
  display: none;
}

.wg-default,
.wg-default .country-selector {
  z-index: -1 !important;
}

/* *Custom Accordian******/
.custom-accordian .btn.show:after {
  content: url('../pictures/svgs/circle-arrow-up.svg');
  position: absolute;
  right: 45px;
  /* width: 8px; */
}

/* .payment-page .custom-accordian .btn.show:after {
  width: 8px;
} */

/* .payment-page .custom-accordian .btn.hidden:after {
  width: 8px;
} */

.custom-accordian .btn.hidden:after {
  content: url('../pictures/svgs/circle-arrow-down.svg');
  position: absolute;
  right: 45px;
  /* width: 8px; */
}

.custom-accordian .number-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--light-5);
  flex: 0 0 auto;
  margin-right: 20px;
  transition: border-color 0.25s, background-color 0.5s;
}

.custom-accordian .btn.show .number-circle {
  background: var(--primary);
  border-color: var(--primary);
  /* margin-right: 20px; */
}

/* .custom-accordian .btn.show .number-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  background: var(--primary);
  border: 1px solid var(--primary);
  margin-right: 20px;
} */

/*******Custom Form******/

.custom-form .form-control,
.booking_accordion .custom-form .login-popup .user-form input,
.booking_accordion .custom-form .otp-popup .user-form input,
.booking_accordion .otp-input input {
  height: 45px;
  background-color: var(--dark-9) !important;
  border: 1px solid #888888;
  font-weight: 600;
  border-radius: 6px;
  color: var(--light-5);
  margin-bottom: 0px;
  font-size: 14px;
  padding: 0 12.5px;
  transition: font-size 0.5s, border-color 0.25s, color 0.25s;
  display: flex;
  align-items: center;
}

.custom-form .form-control.code {
  background-color: var(--dark-9) !important;
  border-radius: 6px;
  border: 1px solid #888888;
  height: 45px;
  width: unset !important;
}

.custom-form .form-control.code .btn {
  height: 100% !important;
}

.custom-form .error {
  margin-top: 4px !important;
  margin-bottom: 8px !important;
  /* height: auto !important; */
  margin-left: 8px;
}

.booking_accordion .custom-form .error {
  margin-left: 0;
}

/* .custom-form label > span {
  margin-left: 8px;
} */

/********MINI-FESTIVALS********/

.page-filters > div {
  margin-right: 30px;
}

.mini-festival-content .vertical-separator {
  width: 0em;
  height: 3em;
  border: 0.001em solid #d8d9de;
  margin: 0;
  box-sizing: inherit;
}

.banner {
  color: var(--light-5);
  position: relative;
  background: linear-gradient(
    359.67deg,
    var(--dark-1) 0.29%,
    rgba(23, 22, 29, 0) 99.71%
  );
}

.banner .banner-img {
  width: 100%;
  height: 650px;
}

.banner .banner-img .poster-img {
  width: 100%;
  height: 650px;
  object-fit: cover;
  object-position: center;
}

.banner .banner-content {
  position: absolute;
  top: 40px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  background: linear-gradient(
    359.67deg,
    var(--dark-1) 0.29%,
    rgba(23, 22, 29, 0) 99.71%
  );
}

.banner .banner-content .heading {
  font-weight: 700;
  font-family: 'Modelica Bold';
}

.banner .banner-content .scroll-down {
  position: relative;
  top: 100px;
}

.mini-festival-content .content-header {
  padding-bottom: 20px;
}

.mini-festival-content .content-header .desc {
  font-size: 15px;
  line-height: 22.4px;
  padding-bottom: 20px;
}

.mini-festival-content .content-header .heading {
  font-size: 14px;
}

.mini-festival-content .content-header .top {
  display: grid;
  grid-template-columns: 20% 60%;
  border-bottom: 1px solid var(--light-5);
  /* grid-column-gap: 20px; */
  padding-bottom: 20px;
}

.mini-festival-content .content-header .top h6 {
  font-weight: bold;
  font-family: 'Modelica Bold';

  font-size: 18px;
  padding-top: 08px;
  margin: 0;
}

.mini-festival-content .content-header .bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 08px;
}

.mini-festival-content .content-header .bottom ul {
  list-style-type: none;
  display: inline-flex;
  padding: 0 0 0 10px;
  font-size: 14px;
  color: var(--primary);
}

.mini-festival-content .content-footer .movie-card .movie-name {
  flex: 0 0 100%;
}

/************PAYMENT PAGE************/

.payment-page .or-div .or-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--light-1);
  line-height: 0.1em;
  margin: 30px 0 30px;
}

.payment-page .or-div span {
  background: var(--dark-2);
  padding: 0 30px;
  color: var(--light-1);
}

.payment-page .form-control:focus {
  box-shadow: none;
  color: var(--light-5);
  /* font-size: 17px; */
  border: 1px solid var(--light-5);
}

.payment-booking-details .header {
  display: grid;
  /* grid-template-columns: 45% 45%; */
  grid-template-columns: 40% auto;
  align-items: center;
  /*padding-bottom: 16px;*/
  /* grid-column-gap: 16px; */
  grid-column-gap: 32px;
  padding: 50px 30px 20px 30px;
}
.text_pop {
  font-size: 16px;
}
.btn-main-pop {
  height: 45px;
  border-radius: 7px;
  font-size: 16px;
  font-weight: 600;
  padding: 0 2rem;
  background-color: var(--yellow-shade);
  color: var(--light-2);
  border: 1px solid var(--yellow-shade);
  outline: 0;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, color 0.5s, border-color 0.5s, transform 0.25s;
}
.btn-main-pop:active {
  transform: scale(0.9);
}
.btn-main-pop.inactive {
  background: var(--dark-3);
  pointer-events: none;
  cursor: unset;
}
.btn-main-pop:focus {
  outline: none;
}
.btn-main-pop:hover {
  background-color: white;
  color: var(--yellow-shade);
  border: 1px solid var(--yellow-shade);
  transition: background-color 0.5s, color 0.5s, border-color 0.5s, transform 0.25s;
}
.last_sec_pop {
  align-items: center;
  padding: 16px 20px 0px 20px;
}
.seat-type-popup .payment-booking-details .header {
  grid-template-columns: 34% auto;
}

.payment-booking-details .header .right {
  /* margin-left: auto; */
}
.right_title {
  line-height: 2rem;
}
.private_pop_spacing {
  margin-bottom: 40px;
  margin-top: 40px;
}
.middle_private_pop {
  font-size: 16px;
  display: flex;
  background-color: #343438;
  align-items: center;
  padding: 10px 30px;
  justify-content: space-between;
}
.add_private_pop {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: #343438;
  font-size: 16px;
}
.private_popup_btn {
  color: white;
  border: 1px solid #343438;
  border-radius: 3px;
  height: 19px;
  /*width: 114px;*/
  background-color: #343438;
  font-size: 12px;
  font-weight: 600;
  padding: 0 24px;
}
.private_popup_btn:hover {
  color: white;
  background-color: var(--yellow-shade);
  border: 1px solid var(--yellow-shade);
}
.payment-booking-details .header .right > div {
  padding-bottom: 10px;
}

.payment-booking-details .header .movie-img {
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: top;
  /*border-radius: 07px;*/
  /* max-width: 180px; */
}
.year_private {
  font-size: 10px;
  color: #888888;
  padding-left: 10px;
}
.final_heading_private {
  color: var(--yellow-shade);
  font-size: 12px;
  text-transform: uppercase;
}
.payment-booking-details .header .title {
  font-size: 18px;
  font-weight: bold;
  font-family: 'SansRegular';
  letter-spacing: 0.5px;
  display: flex;
  align-items: baseline;
}

.payment-booking-details .header .features {
  font-size: 14px;
  font-weight: 600;
  padding-top: 05px;
}

.payment-booking-details .fs-13 {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.payment-booking-details .fs-10 {
  font-size: 10px;
  font-weight: 100;
}

.payment-booking-details .fs-16 {
  font-size: 16px !important;
}

.payment-booking-details .fs-22 {
  font-size: 22px !important;
}

.payment-booking-details .header .movie-imbd .value {
  color: var(--green-shade);
  font-weight: 700;
  font-family: 'Modelica Bold';
}

.payment-booking-details .header .movie-rating .value {
  color: var(--primary);
  font-weight: 700;
  font-family: 'Modelica Bold';
}

/* .payment-booking-details .middle div > p:first-child {
  font-size: 13px;
  letter-spacing: 0.5px;
}

.payment-booking-details .middle div > p:last-child {
  font-size: 16px;
  font-weight: bold;
  font-family: "Modelica Bold";

  letter-spacing: 0.5px;
  padding-top: 03px;
}
.payment-booking-details .middle .heading {
  font-size: 13px;
  letter-spacing: 0.5px;
}

.payment-booking-details .middle .content {
  font-size: 16px;
  font-weight: bold;
  font-family: "Modelica Bold";
  letter-spacing: 0.5px;
  padding-top: 03px;
} */

.payment-booking-details .middle {
  /*border-top: 1px solid var(--light-4);*/
  /*border-bottom: 1px solid var(--light-4);*/
  padding-top: 20px;
}
.pop_total {
  font-size: 16px;
}
.total_pop {
  font-size: 16px;
}
.total_para_pop {
  font-size: 10px;
}
.all_btn_pop {
  padding: 15px 20px 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-booking-details .middle .modifier-btn {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  /*padding: 20px 20px;*/
  margin-left: 0.5rem;
}

.payment-booking-details .middle .modifier-btn span:nth-child(odd):not(.inactive) {
  cursor: pointer;
}

.payment-booking-details
  .middle
  .modifier-btn
  span:nth-child(odd):not(.inactive):hover {
  transform: scale(1.15);
}

.payment-booking-details
  .middle
  .modifier-btn
  span:nth-child(odd):not(.inactive):active {
  transform: scale(0.95);
}

.payment-booking-details .middle .modifier-btn span {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd) {
  font-size: 25px;
  border: 1px solid var(--yellow-shade);
  border-radius: 50%;
  color: var(--dark-3);
  height: 28px;
  width: 28px;
  flex: 0 0 28px;
  box-sizing: content-box;
  line-height: 1;
  transition: transform 0.5s, background-color 0.5s;
  background-color: var(--yellow-shade);
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd):hover,
.fnb-card .fnb-content-row:last-of-type .modifier-btn span:nth-child(1):active {
  color: white;
  border-color: var(--yellow-shade);
  /*background-color: white;*/
}

.payment-booking-details .middle .modifier-btn > span:nth-child(even) input {
  margin: 0 8px;
  width: 5ch;
  background-color: transparent;
  border: 1px solid #555558;
  text-align: center;
  color: white;
  border-radius: 20px;
}

.payment-booking-details .middle .modifier-btn > span:nth-child(odd).inactive {
  color: var(--dark-3);
  border-color: var(--dark-3);
  pointer-events: none;
  cursor: default;
  align-items: flex-start;
}

.payment-booking-details .middle .checkbox-row {
  display: flex;
  align-items: center;
  /*margin: 0 0 4px 24px;*/
  margin-left: 0.5rem;
}

.payment-booking-details .middle .checkbox-row > p {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.payment-booking-details .footer {
  padding: 10px 20px;
  /*border-bottom: 1px solid var(--light-4);*/
  margin-bottom: 24px;
  background-color: var(--book-cnf-header);
  text-transform: uppercase;
}

.back-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: space-between;
}

.back-header .back-arrow-img {
  width: 18px;
  cursor: pointer;
}

.back-header .text {
  font-size: 16px;
  padding-left: 15px;
  cursor: pointer;
}

.payment-page .payment-submit-btn,
.ticket-page .payment-submit-btn {
  background: var(--cinerji-Pantone-Burgandy) !important;
  /* background: #1f1c3c !important; */
  color: var(--light-5);
  /* height: 56px; */
  height: 45px;
  width: 100%;
  border-radius: 07px;
  /* border: 1px solid var(--primary); */
  border: 1px solid #1f1c3c;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
  outline: 0;
  transition: color 0.25s, background-color 0.5s, transform 0.5s;
}

.promo-code-input > div > p {
  font-size: 12px;
  font-weight: 700;
  color: var(--light-7);
  margin-bottom: 8px;
}

.promo-code-input .promo_code_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.payment-page .payment-submit-btn,
.ticket-page .payment-submit-btn {
  background-color: var(--primary);
  color: var(--checkout-signin-btn);
}
.payment-page .payment-submit-btn:hover,
.ticket-page .payment-submit-btn:hover {
  /* background-color: var(--checkout-signin-btn); */
  background-color: white !important;
  /* color: var(--primary); */
  color: #1f1c3c;
}

.payment-page .payment-submit-btn:active,
.ticket-page .payment-submit-btn:active {
  transform: scale(0.9);
}

.text-primary {
  /* color: var(--primary) !important; */
  color: #1f1c3c;
}

.payment-page .custom-accordian {
  background-color: var(--dark-2);
  border-radius: 12px 12px 0px 0px;
  padding: 16px 24px;
  margin-bottom: 06px;
}

.payment-page .custom-accordian.inactive > .btn {
  pointer-events: none;
  cursor: default;
}

.payment-page .custom-accordian span:not(.code) {
  /* width: 65%; */
  /* padding-right: 2%; */
  text-align: left;
  font-weight: 600;
}

.payment-page .custom-accordian button span:not(.code) {
  line-height: 1.2;
}

/* .payment-page .custom-accordian .collapse {
  padding-bottom: 34px;
} */

.payment-page .custom-accordian .btn {
  color: var(--light-5);
  text-decoration: none;
  font-size: 18px;
  /* height: 72px; */
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 15%;
}
.InputContainer {
  color: black !important;
}
.StripeElement {
  /* border: 1px solid var(--red); */
  border-radius: 8px;
  padding: 10px;
  background-color: var(--light-7);
}
.StripeElement input {
  color: black !important;
}
.StripeElement .InputElement::placeholder {
  color: #000 !important;
}
.StripeElement .ElementsApp .InputElement {
  color: #000 !important;
}
/* .payment-page .custom-accordian .collapse {
  margin-top: 30px;
} */

/* .payment-page .custom-accordian #collapse-padding {
  padding-top: 24px;
  padding-bottom: 18px;
} */

.payment-page .agree-check {
  font-size: 12px;
  color: var(--light-1);
  padding: 0 0 0 22px;
  cursor: pointer;
}

.payment-page .agree-check .form-check-input {
  height: 16px;
  width: 16px;
  /* margin-top: 2px; */
  margin-top: 5px;
}

.payment-page .next-step {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-1);
  font-size: 14px;
  letter-spacing: 0.5px;
  padding-top: 24px;
}

.payment-page .next-step p {
  padding-right: 10px;
  cursor: pointer;
}

.payment-page .payment-mode > div {
  display: grid;
  grid-template-columns: 10% auto;
  align-items: center;
  height: 56px;
  border: 1px solid var(--light-1);
  border-radius: 12px;
  margin-top: 18px;
  grid-column-gap: 20px;
  padding: 0 0 0 24px;
  position: relative;
  cursor: pointer;
}

.payment-page .payment-mode > div:after {
  content: url('../pictures/svgs/right-white-arrow.svg');
  position: absolute;
  right: 30px;
  top: 17px;
}

.payment-page .payment-mode > div img {
  margin-left: auto;
}

.payment-page .payment-mode > div .text {
  margin-bottom: 0;
  font-size: 16px;
}

.payment-page .promo-code-input {
  /* display: grid; */
  /* grid-template-columns: 62% 38%; */
  /* grid-template-columns: 66% 34%; */
  /* margin-top: 20px; */
  /* padding-top: 20px; */
}

.payment-page .promo-code-input .form-control,
.gift_card_topup_step_two.promo-code-input .form-control {
  /* height: 42px; */
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  background: var(--light-2);
  border-radius: 7px;
  color: var(--promo-input);
}

.payment-page .payment-success img {
  /*width: clamp(85px, 10vw, 110px);*/
}

.payment-page .payment-success h2 {
  font-size: clamp(18px, 2vw, 24px);
  font-family: 'SansSemiBold';
  font-weight: 700;
  margin-bottom: 16px;
}

.payment-page .payment-success p {
  font-size: clamp(12px, 1.2vw, 14px);
  font-family: 'SansSemiBold';
  font-weight: 700;
  margin-bottom: 20px;
}

.payment-page .promo-code-input .promo-btn {
  /* background: var(--primary); */
  background: #1f1c3c;
  color: #fff;
  border: 1px solid #1f1c3c;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-radius: 0px 4px 4px 0px;
  outline: none !important;
}

.payment-page .promo-code-input .promo-btn.remove-code {
  border-radius: 8px;
  min-height: 35px;
  transition: transform 0.5s;
}

.payment-page .promo-code-input .promo-btn.remove-code:hover {
  background-color: var(--light-5);
  color: var(--primary);
}

.payment-page .promo-code-input .promo-btn.remove-code:active {
  transform: scale(0.9);
}

.payment-page .payment-success > div {
  /* margin-bottom: 30px; */
  /* font-family: "Modelica Medium"; */
}

.payment-page .payment-success .grey-font {
  color: var(--light-1);
  font-size: 18px;
}

.otp-form .otp-input {
  padding: 30px 0 20px 0;
}

.otp-form .otp-input > div {
  justify-content: center;
}

.otp-form .next-step {
  padding-top: 30px;
}

.payment-page .payment-card label {
  display: flex;
  margin-left: auto;
}

.payment-page .payment-card .form-control {
  font-size: 16px;
  text-align: center;
  margin-bottom: 14px;
}

.payment-page .payment-card .grid-col {
  display: grid;
  grid-template-columns: 70% 25%;
  grid-column-gap: 5%;
}

@media (max-width: 1024px) {
  .payment-page .custom-accordian {
    padding: 10px 10px !important;
  }

  .payment-page .custom-accordian .btn {
    font-size: 16px;
  }

  .custom-accordian .number-circle {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    line-height: 31px;
  }

  .custom-accordian .btn.show .number-circle {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    line-height: 31px;
  }

  .custom-accordian .btn.hidden:after {
    /* right: 20px; */
  }
}

/*******custom-radio-group****/

.custom-radio-group.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 07px !important;
  border-bottom-left-radius: 07px !important;
}

.custom-radio-group.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 07px !important;
  border-bottom-right-radius: 07px !important;
}

.custom-radio-group {
  /* width: 100%; */
  display: flex;
  /* justify-content: space-between; */
}

.custom-radio-group .btn {
  border: 1px solid var(--dark-3);
  box-sizing: border-box;
  border-radius: 7px;
  margin: 0 14px 0 0;
}

.custom-radio-group .btn.active {
  background: var(--primary);
  color: var(--light-5);
  border: 1px solid var(--primary);
}

.share-ticket-popup .wrapper {
  position: relative;
  color: var(--light-5);
  background-color: var(--dark-1);
  padding: 44px;
  border-radius: 20px;
  border: 1px solid var(--light-5);
}

.share-ticket-popup .close-btn-main {
  position: absolute;
  right: 23px;
  top: 20px;
  cursor: pointer;
}

.share-ticket-popup .copy-link-input {
  height: 100%;
  border: none;
  box-shadow: none;
}

/*
.share-ticket-popup .copy-link-input:focus,
.share-ticket-popup .copy-link-input:active {
  box-shadow: none;
} */

/*********----------------------POPUPS------------------------********/

.show-details-popup .wrapper,
.seat-type-popup .wrapper,
.register-popup .wrapper,
.otp-popup .wrapper,
.register-complete-popup .wrapper,
.seat-layout-popup .wrapper {
  position: relative;
  color: var(--light-5);
  background-color: var(--dark-1);
  padding: 44px;
  border-radius: 20px;
  border: 1px solid var(--light-5);
}
.show-details-popup .wrapper {
  padding: 15px !important;
}
.login-popup .wrapper {
  position: relative;
  color: var(--light-5);
  background-color: var(--dark-1);
  padding: 44px;
  border-radius: 7px;
  border: 1px solid var(--light-5);
}
.show-details-popup .close-btn-main,
.seat-type-popup .close-btn-main,
.register-popup .close-btn-main,
.seat-layout-popup .close-btn-main {
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  z-index: 1;
}
/* .pop_up_home_modal .close-btn-main {
  position: absolute;
  top: -26px;
  right: 22px;
  cursor: pointer;
  z-index: 1;
} */
.show-details-popup .modal-content,
.seat-type-popup .modal-content,
.register-popup .modal-content,
.login-popup .modal-content,
.otp-popup .modal-content,
.register-complete-popup .modal-content,
.share-ticket-popup .modal-content,
.seat-layout-popup .modal-content {
  background-color: transparent;
}

.show-details-popup .poster-img {
  width: 100%;
  height: auto;
  margin-bottom: 34px;
}

.show-details-popup .poster-img img {
  width: 100%;
  height: auto;
  border-radius: 7px;
}

.show-details-popup .heading {
  /*color: var(--dark-4);*/
  font-size: 24px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  margin-bottom: 14px;
}

.show-details-popup .sub-heading {
  color: var(--cinerji-Pantone-Burgandy);
  font-size: 18px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  padding-bottom: 10px;
  margin-bottom: 0;
  width: 100%;
  border-bottom: 1px solid #dcdcde;
}

.show-details-popup .tags {
  margin: 15px 0 05px 0;
}

.show-details-popup svg {
  fill: var(--primary);
}

.show-details-popup svg path {
  fill: var(--primary);
}

.show-details-popup .tags span {
  font-size: 14px;
  /* padding: 7px 26px; */
  height: 30px;
  min-width: 100px;
  letter-spacing: 1px;
  margin: 0 10px 14px 0;
}

.show-details-popup .features .features-part {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}
.new_pop_lay {
  background-color: #1c1b21;
  border: 1px solid #313035;
  border-radius: 10px;
}
.show-details-popup .features .features-part span {
  padding-left: 10px;
}

.show-details-popup .features .features-part > div {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.show-details-popup .btn-main-group {
  /* margin-bottom: 24px; */
  margin-top: 14px;
  display: flex;
  flex-flow: row wrap;
  /* display: grid;
  grid-gap: 0 24px;
  grid-template-columns: repeat(auto-fill, minmax(115px, 1fr)); */
}

.show-details-popup .btn-main-group .tile {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #dcdcde; */
  border: 1px solid var(--cinerji-Pantone-Burgandy);
  border-radius: 7px;
  cursor: pointer;
  background: transparent;
  /* min-width: 124px; */
  min-width: 118px;
  transition: color 0.5s, background-color 0.4s, border-color 0.4s, transform 0.25s;
  font-size: 16px;
  font-weight: 600;
  color: var(--cinerji-Pantone-Burgandy);
  height: 40px;
  outline: 0;
  padding: 0 10px;
}

.show-details-popup .btn-main-group .tile.format {
  min-width: 60px;
}

.show-details-popup .btn-main-group .tile:hover,
.show-details-popup .btn-main-group .tile.active {
  background: var(--cinerji-Pantone-Burgandy);
  outline: 0;
  border: 1px solid var(--cinerji-Pantone-Burgandy);
  color: #fff;
}

.show-details-popup .btn-main-group .tile:active {
  transform: scale(0.95);
}

.show-details-popup .btn-main-group .tile {
  margin-bottom: 24px;
}

.show-details-popup .btn-main-group .tile:not(:last-of-type) {
  margin-right: 14px;
}

.seat-type-popup .title-row {
  margin: 24px 0px;
}
.seat-type-cat-gold {
  background-color: #c09e3f !important;
}
.seat-type-popup .seat-type-cat {
  background-color: #fff;
  padding: 5px 50px;
  /*font-family: "Modelica Bold";*/
  font-size: 20px;
  margin: 16px 0px;
  font-weight: 700;
  text-transform: uppercase;
  /* text-decoration: underline; */
}

.seat-type-popup .title-row .title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'SansSemiBold';
  text-transform: capitalize;
}

.seat-type-popup .selected-seats {
  min-width: 214px;
  height: 28px;
  background: var(--green-shade);
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* font-size: 24px; */
  font-size: 16px;
  font-weight: 700;
  font-family: 'Modelica Bold';
  padding: 0 2rem;
}

.seat-type-popup .seat-picker {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  font-size: 16px;
  font-weight: 700;
}
.pop_hori_div {
  display: none;
  justify-content: center;
  align-items: center;
  width: 36vw;
}
.pop_up_hori {
  display: none;
  width: 50vw;
  background-color: white;
  opacity: 0.1;
  margin-bottom: 0px;
}
.seat-type-popup .seat-picker .seat-picker-row {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 33% 33% 33%;
  align-items: center;
  padding: 10px 60px;
  border-bottom: 1px solid rgba(220, 220, 222, 0.5);
  /*margin-bottom: 24px;*/
  grid-gap: 2%;
  justify-content: center;
  height: 80px;
}

.seat-type-popup .seat-picker .seat-picker-row.headings {
  padding: 10px 60px;
  /* background-color: #29282E; */
  border: none;
}

.seat-type-popup .seat-picker .seat-picker-row.total {
  font-size: 16px;
  text-transform: uppercase;
}

.seat-type-popup .seat-picker .seat-picker-row.total div:first-of-type {
  grid-column: 1/3;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier {
  display: flex;
  justify-content: space-between;
  width: min(151px, 100%);
  height: 32px;
  /*background: var(--dark-3);*/
  /*border-radius: 5px;*/
  overflow: hidden;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  user-select: none;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(n) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(odd) {
  width: 32px;
  height: 100%;
  /* background: var(--light-5); */
  background: var(--cinerji-Pantone-Burgandy);
  color: var(--dark-3);
  cursor: pointer;
  font-size: 30px;
  font-weight: 400;
  border-radius: 50%;
}

.seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(odd):hover {
  /*color: var(--light-5);*/
  /*background-color: var(--dark-3);*/
  color: #fff;
}

.seat-type-popup
  .seat-picker
  .seat-picker-row
  .modifier
  span:nth-child(odd).inactive {
  color: var(--dark-3);
  background-color: var(--light-4);
}

.seat-type-popup
  .seat-picker
  .seat-picker-row
  .modifier
  span:nth-child(even).inactive {
  color: var(--light-4);
}

.seat-type-popup .question {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  margin-bottom: 18px;
}

.seat-type-popup .answer {
  width: 100%;
  height: 45px;
  color: var(--light-1);
  background-color: transparent;
  border: 1px solid #dcdcde;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  padding: 14px 12px;
  margin-bottom: 24px;
}

.register-popup .wrapper,
.login-popup .wrapper,
.otp-popup .wrapper,
.register-complete-popup .wrapper {
  border: none;
  background-color: var(--dark-6);
}

.register-popup .title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  text-align: center;
  margin-bottom: 34px;
}

.register-popup .social-media-btn-main {
  width: 100%;
  background: var(--dark-5);
  border: 1px solid var(--light-1);
  border-radius: 12px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.4s, box-shadow 0.25s;
}

.register-popup .social-media-btn-main:not(:last-of-type) {
  margin-bottom: 24px;
}

.register-popup .social-media-btn-main img {
  position: absolute;
  left: 42px;
}

.register-popup .social-media-btn-main:hover {
  /* color: var(--primary); */
  box-shadow: 2px 2px 5px 5px rgba(255, 255, 255, 0.1);
}

.register-popup .or {
  margin: 34px auto;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Modelica Bold';
  text-align: center;
}

.register-popup .mobile-num {
  display: flex;
  align-items: center;
}

.register-popup .mobile-num .code {
  margin: 0 8px 0 6px;
}

.register-popup .mobile-num .code span {
  font-size: 18px;
  font-weight: 600;
}

.register-popup .mobile-num input {
  border: none;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 600;
  color: var(--light-1);
  border-bottom: 1px solid var(--light-1);
  padding: 0 0 8px 0;
  background-color: transparent;
  line-height: 1;
}

.login-popup .back-btn-main,
.otp-popup .back-btn-main,
.register-complete-popup .back-btn-main {
  /*position: absolute;*/
  position: relative;
  /*top: 44px;*/
  top: 0;
  /*left: 44px;*/
  left: 0;
  cursor: pointer;
}

.login-popup .back-btn-main.__to_right,
.otp-popup .back-btn-main.__to_right,
.register-complete-popup .back-btn-main.__to_right {
  left: calc(100% - 16px);
}

.login-popup .title,
.otp-popup .title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  /* margin-top: 67px;
  margin-bottom: 34px; */
  /* margin-top: 24px; */
  margin-top: 16px;
  margin-bottom: 24px;
}

.login-popup.timeout_popup .title {
  font-size: clamp(16px, 2vw, 24px);
  font-weight: 700;
  font-family: 'SansSemiBold';
  margin-bottom: 8px;
}

.login-popup .user-form,
.otp-popup .user-form {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

.login-popup .user-form label,
.otp-popup .user-form label {
  width: 100%;
}

.login-popup .user-form input,
.otp-popup .user-form input {
  width: 100%;
  height: 50px;
  color: var(--light-5);
  background: var(--dark-5);
  border: 1px solid var(--light-1);
  border-radius: 12px;
  padding: 0 24px;
  margin-top: 8px;
}

.otp-popup label > span,
.login-popup label > span,
.login-popup .error,
.otp-popup .error {
  margin-left: 8px;
}

.booking_accordion .otp-popup label > span,
.booking_accordion .login-popup label > span,
.booking_accordion .login-popup .error,
.booking_accordion .otp-popup .error {
  margin-left: 0;
}

.login-popup .user-form input:not(:last-of-type),
.otp-popup .user-form input:not(:last-of-type) {
  margin-bottom: 16px;
}

.login-popup .user-form .btn-main,
.otp-popup .btn-main,
.register-complete-popup .btn-main {
  width: 100%;
  height: 50px;
}

.login-popup .user-form .btn-main {
  margin-top: 44px;
}

.login-popup__actions {
  margin-top: 10px;
  display: grid;
  gap: 16px;
  align-items: center;
  justify-content: center;
}
.payment_auth_options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  flex-wrap: wrap;
  /*gap: 16px;*/
}
.login-popup__actions > button {
  margin: 0 16px;
  background-color: var(--cinerji-Pantone-Burgandy);
  /* background-color: var(--blue); */
  color: var(--text-color-secondary);
}
.login-popup__actions > button:hover {
  margin: 0 16px;
  background-color: var(--text-color-secondary);
  color: var(--cinerji-Pantone-Burgandy);
  border: 1px solid var(--cinerji-Pantone-Burgandy);
}

.otp-popup .title {
  margin-bottom: 14px;
}

.otp-popup .msg {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  color: var(--light-1);
  margin-bottom: 20px;
}

.otp-popup .otp-input {
  /* margin-bottom: 174px; */
  margin-bottom: 24px;
}

.otp-popup .otp-input > div {
  flex-wrap: wrap;
}

.otp-popup .otp-input input:not(:last-of-type) {
  margin-right: 14px;
}

.otp-popup .otp-input input {
  color: var(--light-5);
}

.otp-popup .otp-input input:focus-within {
  border: 1px solid var(--primary);
  color: var(--primary);
}

.otp-popup .btn-main.inactive {
  background: rgba(232, 48, 138, 0.25);
  pointer-events: none;
  cursor: unset;
}

/*.register-complete-popup .wrapper {*/
/*  text-align: center;*/
/*}*/

.register-complete-popup .title {
  font-size: 34px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  margin-top: 32px;
  text-align: center;
}

.register-complete-popup .msg {
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 64px;
  text-align: center;
}

.seat-layout-popup .wrapper {
  padding: 0 60px 54px 60px;
  overflow: hidden;
}

.seat-layout-popup .title-row {
  background-color: var(--dark-2);
  /* height: 85px; */
  height: 60px;
  align-items: center;
  justify-content: center;
  margin: 0 -60px;
}

.seat-layout-popup .title-row .title {
  /* font-size: 34px; */
  font-size: 30px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  text-align: center;
}

.seat-layout-popup .seat-layout-screen {
  /* margin-top: 44px;
  margin-bottom: 54px; */
  margin-top: 0;
  margin-bottom: 24px;
}

.seat-layout-popup .master-table {
  transform: none;
  margin: 0 auto 24px auto;
  pointer-events: none;
}

/* .seat-layout-popup .master-table .cell {
  height: 30px;
  width: 30px;
  padding: 4px;
  font-size: 12px;
} */

/* .seat-layout-popup .master-table .cell.seat {
  background-size: auto auto;
}

.seat-layout-popup .master-table .cell.lounger {
  background-size: auto auto;
}

.seat-layout-popup .master-table .cell.wheel-chair {
  background-size: auto auto;
} */

.seat-layout-popup .seat-layout-icons {
  margin-bottom: 0;
}

/* .seat-layout-popup .seat-layout-icons img {
  height: auto;
} */

.seat-layout-popup .seat-layout-icons p:not(:last-of-type) {
  margin-right: 28px;
}

.seat-layout-popup .entrance {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin-bottom: 34px;
}

.seat-layout-popup .entrance > div:first-of-type {
  margin-right: 97px;
}

.seat-layout-popup .entrance p {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  margin-left: 14px;
}

/* ----------------Date Picker CSS----------------*/

.date-picker-popup {
  font-size: 14px;
  align-self: flex-end;
}

.date-picker-popup ~ .title,
.show-details-trigger {
  cursor: pointer;
}

.md-icon ~ .title::after,
.date-picker-popup ~ .title::after,
.show-details-trigger::after {
  display: inline-block;
  content: '';
  margin-left: 0.355em;
  vertical-align: middle;
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
  font-size: 16px;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  /* position: static !important; */
}
/*.react-date-picker,*/
/*.react-date-picker *,*/
/*.react-date-picker *:before,*/
/*.react-date-picker *:after {*/
/*  -moz-box-sizing: border-box;*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*}*/
/*.react-date-picker--disabled {*/
/*  background-color: #f0f0f0;*/
/*  color: #6d6d6d;*/
/*}*/
.react-date-picker__wrapper {
  /*display: flex;*/
  /*flex-grow: 1;*/
  /*flex-shrink: 0;*/
  /*border: thin solid gray;*/
  display: none;
}

/*.react-date-picker__inputGroup {*/
/*  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);*/
/*  flex-grow: 1;*/
/*  padding: 0 2px;*/
/*  box-sizing: content-box;*/
/*}*/
/*.react-date-picker__inputGroup__divider {*/
/*  padding: 1px 0;*/
/*  white-space: pre;*/
/*}*/
/*.react-date-picker__inputGroup__input {*/
/*  min-width: 0.54em;*/
/*  height: 100%;*/
/*  position: relative;*/
/*  padding: 0 1px;*/
/*  border: 0;*/
/*  background: none;*/
/*  font: inherit;*/
/*  box-sizing: content-box;*/
/*  -moz-appearance: textfield;*/
/*}*/
/*.react-date-picker__inputGroup__input::-webkit-outer-spin-button,*/
/*.react-date-picker__inputGroup__input::-webkit-inner-spin-button {*/
/*  -webkit-appearance: none;*/
/*  margin: 0;*/
/*}*/
/*.react-date-picker__inputGroup__input:invalid {*/
/*  background: rgba(255, 0, 0, 0.1);*/
/*}*/
/*.react-date-picker__inputGroup__input--hasLeadingZero {*/
/*  margin-left: -0.54em;*/
/*  padding-left: calc(1px +  0.54em);*/
/*}*/
/*.react-date-picker__button {*/
/*  border: 0;*/
/*  background: transparent;*/
/*  padding: 4px 6px;*/
/*}*/
/*.react-date-picker__button:enabled {*/
/*  cursor: pointer;*/
/*}*/
/*.react-date-picker__button:enabled:hover .react-date-picker__button__icon,*/
/*.react-date-picker__button:enabled:focus .react-date-picker__button__icon {*/
/*  stroke: #0078d7;*/
/*}*/
/*.react-date-picker__button:disabled .react-date-picker__button__icon {*/
/*  stroke: #6d6d6d;*/
/*}*/
/*.react-date-picker__button svg {*/
/*  display: inherit;*/
/*}*/
.react-date-picker__calendar {
  width: 276px;
  /*max-width: 100vw;*/
  /*position: absolute;*/
  /*top: 100%;*/
  /*left: 0;*/
  z-index: 99999;
  /*background: transparent;*/
  top: 100% !important;
  /* bottom: 0 !important; */
  /* left: unset !important; */
  /* right: 0 !important; */
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s, height 0.5s;
}

/* @media(max-width:767px){
  .react-date-picker__calendar{
    transform:translateX(-50%) !important;
    right: 0;
    left: unset;
  }
} */

.react-date-picker__calendar--closed {
  /* display: none; */
  opacity: 0;
  visibility: hidden;
  display: block;
  height: 0;
}

.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

/* React calendar css*/

.react-calendar {
  width: 276px;
  max-width: 100%;
  color: var(--light-5);
  background: var(--dark-1);
  border: 1px solid var(--light-5);
  border-radius: 12px;
  font-family: Modelica;
  font-weight: 600;
  line-height: 1.125em;
  overflow: hidden;
  transition: all 0.5s;
}

/*.react-calendar--doubleView {*/
/*  width: 700px;*/
/*}*/

.react-calendar__viewContainer {
  border-top: 1px solid #e8e8e8;
}

.react-calendar__month-view {
  margin: 8px;
}

/*.react-calendar--doubleView .react-calendar__viewContainer {*/
/*display: flex;*/
/*margin: -0.5em;*/
/*}*/
/*.react-calendar--doubleView .react-calendar__viewContainer > * {*/
/*  width: 50%;*/
/*  margin: 0.5em;*/
/*}*/
/*.react-calendar,*/
/*.react-calendar *,*/
/*.react-calendar *:before,*/
/*.react-calendar *:after {*/
/*  -moz-box-sizing: border-box;*/
/*  -webkit-box-sizing: border-box;*/
/*  box-sizing: border-box;*/
/*}*/
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: Modelica;
  font-weight: 600;
  color: var(--light-5);
}

/*.react-calendar button:enabled:hover {*/
/*  cursor: pointer;*/
/*}*/
.react-calendar__navigation {
  height: 40px;
  margin-bottom: min(1em, 0px);
  padding: 4px 8px;
}

.react-calendar__navigation button {
  min-width: 36px;
  /*background: none;*/
  color: var(--light-5);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--primary);
}

.react-calendar__navigation button[disabled] {
  background-color: var(--dark-3);
}

.react-calendar__month-view__weekdays {
  /* display: none !important; */
  /*text-align: center;*/
  /*text-transform: uppercase;*/
  /*font-weight: bold;*/
  /*font-size: 0.75em;*/
}

/*.react-calendar__month-view__weekdays__weekday {*/
/*  padding: 0.5em;*/
/*}*/
/*.react-calendar__month-view__weekNumbers {*/
/*  font-weight: bold;*/
/*}*/
/*.react-calendar__month-view__weekNumbers .react-calendar__tile {*/
/*display: flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*font-size: 0.75em;*/
/*padding: calc(0.75em / 0.75) calc(0.5em / 0.75);*/
/*}*/
/*.react-calendar__month-view__days__day--weekend {*/
/*  color: var(--primary);*/
/*}*/
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--dark-3) !important;
}

/*.react-calendar__year-view .react-calendar__tile,*/
/*.react-calendar__decade-view .react-calendar__tile,*/
/*.react-calendar__century-view .react-calendar__tile {*/
/*  padding: 2em 0.5em;*/
/*}*/
/*.react-calendar__tile {*/
/*max-width: 100%;*/
/*text-align: center;*/
/*padding: 0.75em 0.5em;*/
/*background: none;*/
/*}*/
.react-calendar__tile:disabled {
  background-color: var(--dark-3);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--primary);
}

.react-calendar__tile--now {
  background: unset;
}

/*.react-calendar__tile--now:enabled:hover,*/
/*.react-calendar__tile--now:enabled:focus {*/
/*  background: #ffffa9;*/
/*}*/
.react-calendar__tile--hasActive {
  background: var(--primary);
}

/*.react-calendar__tile--hasActive:enabled:hover,*/
/*.react-calendar__tile--hasActive:enabled:focus {*/
/*  background: #a9d4ff;*/
/*}*/
.react-calendar__tile--active {
  background: var(--primary);
  color: var(--light-5);
}

/*.react-calendar__tile--active:enabled:hover,*/
/*.react-calendar__tile--active:enabled:focus {*/
/*  background: #1087ff;*/
/*}*/
/*.react-calendar--selectRange .react-calendar__tile--hover {*/
/*  background-color: #e6e6e6;*/
/*}*/

@media (max-width: 576px) {
  .show-details-popup .wrapper,
  .seat-type-popup .wrapper,
  .register-popup .wrapper,
  .login-popup .wrapper,
  .otp-popup .wrapper,
  .register-complete-popup .wrapper,
  .seat-layout-popup .wrapper,
  .share-ticket-popup .wrapper {
    padding: 44px 24px 24px 24px;
    border-radius: 12px;
  }

  /* .show-details-popup .close-btn-main,
  .seat-type-popup .close-btn-main,
  .register-popup .close-btn-main {
    top: 12px;
    right: 12px;
  } */

  .seat-type-popup .seat-picker {
    font-size: 14px;
  }

  .seat-type-popup .seat-picker .seat-picker-row {
    grid-template-columns: 33% 33% 33%;
    padding: 10px 30px;
    /* margin-bottom: 16px; */
    grid-gap: 4%;
    height: 60px;
  }

  .seat-type-popup .question {
    font-size: 18px;
    /*margin-bottom: 18px;*/
  }

  .seat-type-popup .seat-picker .seat-picker-row .modifier {
    height: 28px;
    font-size: 16px;
  }

  .seat-type-popup .seat-picker .seat-picker-row .modifier span:nth-child(odd) {
    width: 28px;
    font-size: 22px;
  }

  .seat-type-popup .seat-picker .seat-picker-row.total {
    font-size: 14px;
  }

  /* .login-popup .back-btn-main, */
  /* .otp-popup .back-btn-main, */
  /* .register-complete-popup .back-btn-main { */
  /* top: 24px; */
  /* top: 0; */
  /* left: 24px; */
  /* left: 0; */
  /* } */

  .otp-input input {
    /* width: 36px !important; */
    width: 100% !important;

    /* text-align: center; */
    height: 36px;
    margin-right: 6px;
  }

  .seat-layout-popup .wrapper {
    padding: 0 24px 34px 24px;
  }

  .seat-layout-popup .title-row {
    height: 60px;
    margin: 0 -24px;
  }

  .seat-layout-popup .title-row .title {
    font-size: 20px;
  }

  .seat-layout-popup .seat-layout-screen {
    margin-top: 24px;
    margin-bottom: 34px;
  }

  .seat-layout-popup .seat-layout-icons p:not(:last-of-type) {
    margin-right: 16px;
  }

  .share-ticket-popup .share-social-btn {
    justify-content: center !important;
  }

  .share-ticket-popup .share-social-btn div,
  .share-ticket-popup .share-social-btn a {
    width: 100%;
  }

  .share-ticket-popup .share-social-btn a {
    display: inline-block;
  }

  .share-ticket-popup .share-social-btn .social-btn {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .show-details-popup .pop_up_home_modal {
    max-width: 450px;
  }
  .show-details-popup .modal-dialog,
  .seat-type-popup .modal-dialog {
    max-width: 626px;
  }

  .register-popup .modal-dialog,
  .login-popup .modal-dialog,
  .otp-popup .modal-dialog,
  .register-complete-popup .modal-dialog {
    max-width: 558px;
  }

  .seat-layout-popup .modal-dialog {
    max-width: 788px;
  }

  .share-ticket-popup .share-social-btn > div:not(:last-child) {
    margin-right: 16px;
  }
}

.format-radio-group .btn {
  width: 60px;
  height: 40px;
}

.lang-radio-group .btn {
  width: 124px;
  height: 40px;
}

.share-ticket-popup .title-row {
  margin-top: -20px;
  padding-bottom: 23px;
}

.share-ticket-popup .title {
  font-weight: bold;
  font-family: 'Modelica Bold';

  font-size: 20px;
  letter-spacing: 0.5px;
}

.share-ticket-popup .copy-link-row > div:last-child {
  display: grid;
  /* grid-template-columns: 88% 12%; */
  grid-template-columns: auto 80px;
  background-color: var(--light-5);
  color: var(--dark-6);
  border-radius: 05px;
  height: 50px;
}

.share-ticket-popup .share-social-btn {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--light-5);
  /* padding-top: 12px; */
  margin-top: 18px;
  flex-wrap: wrap;
}

.share-ticket-popup .form-control {
  border: 1px solid transparent;
}

.share-ticket-popup .share-social-btn .social-btn {
  border-radius: 05px;
  height: 45px;
  color: var(--light-5);
  font-size: 14px;
  font-weight: 600;
  /* letter-spacing: 0.5px; */
  min-width: 170px;
  border: 1px solid transparent;
  outline: none;
  margin-top: 12px;
}

.share-ticket-popup .social-btn::before {
  content: ' ';
  display: inline-block;
  background-size: contain !important;
  height: 16px;
  width: 20px;
  top: 03px;
  position: relative;
  background-repeat: no-repeat !important;
  margin-right: 05px;
}

.share-ticket-popup .link-copied-text {
  margin-bottom: 13px;
  font-size: 14px;
  text-align: right;
}

.share-ticket-popup .link-copied-text span {
  background: rgba(32, 176, 56, 0.15);
  border-radius: 5px;
  color: #20b038;
  padding: 07px 14px;
}

.share-ticket-popup .fb-btn {
  background: #507cc0;
}

.share-ticket-popup .fb-btn::before {
  background: url('../pictures/svgs/facebook.svg');
  width: 10px;
}

.share-ticket-popup .twitter-btn {
  background: #55acee;
}

.share-ticket-popup .twitter-btn::before {
  content: url('../pictures/svgs/twitter.svg');
}

.share-ticket-popup .whatsapp-btn {
  background: #20b038;
}

.share-ticket-popup .whatsapp-btn::before {
  content: url('../pictures/svgs/whatsapp.svg');
}

.share-ticket-popup .signal-btn {
  background: #9bdbf4;
}

.share-ticket-popup .signal-btn::before {
  content: url('../pictures/svgs/signal.svg');
}

@media (min-width: 1400px) {
  .payment-page
    .custom-accordian
    span:not(.new-date-picker):not(.DayPicker-NavButton) {
    width: 100%;
  }

  .page-filters > div {
    margin-right: 50px;
  }

  .banner .banner-content .heading {
    font-size: 74px;
  }

  .banner .banner-content .desc {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    line-height: 25px;
  }

  .mini-festival-content .content-header .top {
    grid-template-columns: 16% 60%;
  }

  .mini-festival-content .content-header .bottom {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: inherit;
  }
}

/************************************** Start Final Ticket **************************************/
.booking_data_final {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 400;
  flex-wrap: wrap;
  line-height: 1.5rem;
  padding-top: 5px;
}
.spacing_payment_method {
  margin-left: 102px;
}
.final-ticket {
  /*border: 1px solid #edf2f5;*/
  border-radius: 7px;
  border: 1px solid #313035;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.42);
}

.final-ticket::after {
  /*content: "";*/
  position: absolute;
  height: 26px;
  width: 30px;
  border-radius: 55%;
  border: 1px solid var(--light-5);
  bottom: -12px;
  left: -10px;
  background: var(--dark-1);
}

.final-ticket .ticket-content::after {
  /*content: "";*/
  position: absolute;
  height: 26px;
  width: 30px;
  border-radius: 55%;
  border: 1px solid var(--light-5);
  bottom: -12px;
  right: -10px;
  background: var(--dark-1);
}

.final-ticket .ticket-header {
  background-color: var(--cinerji-Pantone-Burgandy);
  /* background-color: #1f1c3c; */
  border-bottom: 1px solid #1f1c3c;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 34px;
  height: 80px;
}
.ticket_text {
  margin-bottom: 30px;
}
.qr_sec {
  height: 290px;
}
.qr_para {
  font-size: 12px;
  font-weight: bold;
  color: black;
}
.final-ticket .ticket-header h6 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'SansSemiBold';
}

.final-ticket .ticket-content {
  /*padding: 20px 34px;*/
}

.final-ticket .ticket-content .top {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 34px;
}

.final-ticket .ticket-content .top .left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.final-ticket .ticket-content .top .right canvas {
  display: block;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.final-ticket .ticket-content .top .left > div:first-child {
  flex: 0 0 25%;
}

.final-ticket .ticket-content .top .left > div:last-child {
  flex: 0 0 70%;
  margin-left: 20px;
}

.final-ticket .ticket-content .top .heading {
  font-size: 28px;
  font-weight: bold;
  font-family: 'Modelica Bold';

  letter-spacing: 0.9px;
}

.final-ticket .ticket-content .top .movie-img {
  /*border-radius: 07px;*/
  /* margin-right: 21px; */
  /* width: min(180px, 30%); */
  width: min(180px, 41%);
  border-radius: 10px;
}

/* .final-ticket .ticket-content .top .features {
  padding: 8px 0;
} */

.final-ticket .ticket-content .top .features p {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.final-ticket .ticket-content .top .right p {
  font-size: 14px;
  text-align: center;
  padding-top: 10px;
}

/* .final-ticket .ticket-content .bottom:before {
  content: url("../pictures/svgs/grey-border.svg");
} */

.final-ticket .ticket-content .bottom .left .grid-col-2,
.final-ticket .ticket-content .bottom .left .grid-col-3 {
  padding: 30px 0 0 0;
}

/* s */
.final-ticket .ticket-content .bottom .left .heading .text-shadow {
  color: #fff !important;
  text-shadow: 2px 2px 5px black !important;
}

.final-ticket .ticket-content .bottom .left .heading {
  color: var(--light-1);
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.new_heading {
  color: var(--light-5);
  font-size: 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  font-family: 'SansMedium';
}
.final-ticket .ticket-content .bottom .left .content {
  font-weight: 700;
  font-family: 'SansMedium';
  font-size: 16px;
  padding-top: 05px;
  word-break: break-word;
  /* color: #fff; */
  color: #000000;
}
/*.final-ticket .ticket-content .bottom .right* the original class was */
.ticket-btn {
  width: 20vw;
  /* padding: 0 30px; */
  height: 45px;
  background-color: var(--cinerji-Pantone-Burgandy);
  /* background: var(--light-5); */
  border-radius: 5px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  /*text-transform: uppercase;*/
  position: relative;
  margin-bottom: 20px;
  outline: 0;
  border: 1px solid transparent;
}
.ticket-btn.loader-text::after {
  content: '';
  border: 4px solid var(--primary);
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}
.ticket-btn:hover {
  color: white;
  /* border-color: var(--primary); */
}
.final-ticket .ticket-content .bottom .right .ticket-btn.loader-text {
  cursor: default;
  pointer-events: none;
}

.final-ticket .ticket-content .bottom .right .ticket-btn.loader-text::after {
  content: '';
  border: 4px solid var(--primary);
  border-top: 2px solid transparent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}

.final-ticket .ticket-content .bottom .right .ticket-btn:hover {
  color: var(--primary);
  border-color: var(--primary);
}

.final-ticket .ticket-content .bottom .right .ticket-btn svg,
.final-ticket .ticket-content .bottom .right .ticket-btn path {
  fill: var(--primary);
}

.final-ticket .order-details .order-heading {
  font-weight: 700;
  font-family: 'Modelica Bold';
  font-size: 20px;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid white;
}
.final-ticket .order-details .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.final-ticket .order-details .item .single-item {
  color: var(--light-1);
  font-size: 14px;
}

.final-ticket .order-details .item .item-price {
  font-weight: 700;
  font-family: 'Modelica Bold';
  font-size: 18px;
}

.final-ticket .text-light,
.concession .text-light {
  color: var(--light-1) !important;
  font-size: 14px;
}
.qr-border {
  /* border: 4px solid var(--light-5); */
}

.ticket-btn::before {
  content: '';
  width: 20px;
  height: 20px;
  background-color: #fff;
  mask-repeat: no-repeat;
  mask-position: center;
}

.email-btn:before {
  /* content: url("../pictures/svgs/email.svg"); */
  mask-image: url('../pictures/svgs/newemail.svg');
  position: relative;
  /* left: -7px; */
  /* top: 4px; */
  margin-right: 6px;
}

.mobile-btn:before {
  mask-image: url('../pictures/svgs/sendmobile.svg');
  position: relative;
  /* left: -7px; */
  /* top: 6px; */
  margin-right: 6px;
}

.share-btn:before {
  mask-image: url('../pictures/svgs/newshare.svg');
  position: relative;
  /* left: -7px; */
  /* top: 4px; */
  margin-right: 6px;
}

.download-btn:before {
  content: url('../pictures/svgs/download-pdf.svg');
  position: relative;
  left: -7px;
  top: 4px;
}

.final-ticket .ticket-content .bottom .left .grid-col-1,
.final-ticket .ticket-content .bottom .left .grid-col-2,
.final-ticket .ticket-content .bottom .left .grid-col-3 {
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 10%;
  grid-gap: 15% 7%;
}

.grey-dashed-border img {
  width: 100%;
}

.payment_details_new .movie-img {
  /* height: 200px; */
  display: flex;
  /* align-items: center; */
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 14px;
}
.payment_details_new .movie-img img {
  /* max-width: 100%; */
  /* height: 100%; */
  width: 130px;
  transition: 300ms;
  margin-top: 10px;
  border-radius: 10px;
}

.movie-img img:hover {
  transform: scale(1.1);
}
.payment_details_new .movie_info {
  align-items: stretch !important;
}

.payment_details_new .title_n_value {
  font-size: clamp(10px, 1.2vw, 14px);
  font-weight: 700;
  font-family: 'SansSemiBold';
  text-align: center;
}

.payment_details_new .title_n_value.__date_n_time {
  text-align: center;
}

.payment_details_new .title_n_value.__date_n_time > p:first-of-type {
  font-size: clamp(12px, 1.5vw, 16px);
  font-weight: 700;
  font-family: 'SansSemiBold';
}

.payment_details_new .title_n_value:not(.last-of-type) {
  margin-bottom: 16px;
}

.payment_details_new .title_n_value > h6 {
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 6px;
  color: var(--primary);
  text-transform: uppercase;
}

.payment_details_new.movie_info_wrapper.sticky_movie_info {
}

.payment_details_new.movie_info_wrapper .movie-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 500px;
}

.payment_details_new.movie_info_wrapper .movie_info_scroll_space {
  height: 100px;
}

.payment_details_new .payment_details_rows {
  border-top: 1px solid var(--dark-12);
  padding: clamp(8px, 1.25vw, 16px) 0;
  margin-top: 4px;
  color: var(--dark-11);
}

.payment_details_new .payment_details_rows > section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /* gap: 36px; */
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.payment_details_rows_final > section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /*gap: 36px;
  grid-template-columns: repeat(2,1fr);
  gap: 10px;*/
  font-size: 12px;
  padding-top: 10px;
  font-family: 'SansRegular';
  font-weight: 400;
}
.payment_details_new .payment_details_rows > section:not(:last-of-type) {
  /* margin-bottom: 4px; */
  margin-bottom: 8px;
}

.payment_details_new .payment_details_rows > section .left_value {
  font-size: 14px;
  font-weight: 700;
  font-family: 'SansRegular';
  text-align: left;
  word-break: break-word;
}

.payment_details_new .payment_details_rows > section .left_value span {
  font-size: 12px;
  /* font-size: 14px; */
  font-weight: 400;
  margin-left: 4px;
  font-family: 'SansRegular';
  /*white-space: break-spaces;
  word-break: break-word;*/
}
.payment_details_new .payment_details_rows_final > section .right_value {
  font-size: 11px;
}
.payment_details_new .payment_details_rows > section .right_value {
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  font-family: 'SansRegular';
  white-space: nowrap;
}

.payment_details_new .movie-bottom > section {
  padding: 24px;
}

.payment_details_new .movie-bottom > section > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
}

.payment_details_new .movie-bottom h6 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 6px;
}

.payment_details_new .movie-bottom h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.payment_details_new .movie-bottom h4 {
  font-size: 16px;
  font-weight: 700;
  font-family: 'SansSemiBold';
  margin-bottom: 0;
}

.payment_details_new .payment_details_total h5 {
  font-size: 13px;
  font-weight: 400;
  /* color: var(--primary); */
  color: var(--blue);
  margin-bottom: 0;
}

.payment_details_new .payment_details_total h4 {
  font-size: 13px;
  font-weight: 700;
  /* color: var(--primary); */
  color: var(--blue);
  margin-bottom: 0;
}

.payment_details_new .payment_details_total h6 {
  font-size: 9px;
  font-weight: 400;
  color: var(--light-7);
  margin-bottom: 0;
  margin-top: 4px;
  text-align: left;
}

.payment_details_new.movie_info_wrapper .movie_info_mobile .movie_info_language {
  /* height: 12.5px; */
  /*height: 14px;*/
  height: 20px;
  font-size: 9px;
  line-height: 14px;
}

.booking_accordion .btn-main.guest_btn {
  background-color: var(--checkout-signin-btn);
  border-color: var(--blue);
  color: var(--blue);
}

.booking_accordion .btn-main.guest_btn:hover,
.booking_accordion .btn-main.guest_btn:active,
.booking_accordion .btn-main.guest_btn.active {
  color: #fff !important;
  border-color: var(--blue) !important;
  background-color: var(--blue) !important;
}

.booking_accordion .btn-main.guest_btn_pvt {
  background-color: var(--light-5);
  border-color: var(--light-5);
  color: var(--yellow-shade);
}

.booking_accordion .btn-main.guest_btn_pvt:hover,
.booking_accordion .btn-main.guest_btn_pvt:active,
.booking_accordion .btn-main.guest_btn_pvt.active {
  color: var(--light-5) !important;
  border-color: var(--yellow-shade) !important;
  background-color: var(--yellow-shade) !important;
}

.booking_accordion .not_a_member {
  font-size: 14px;
}

.booking_accordion .not_a_member a {
  font-size: 16px;
  text-decoration: underline;
  margin: 0 5px;
}

.golden-link a {
  color: var(--yellow-shade) !important;
}

@media (max-width: 767px) {
  .payment_details_new .title_n_value {
    font-weight: 600;
    font-family: 'SansRegular';
  }
  .booking_accordion .not_a_member {
    display: flex;
    justify-content: center;
    /*gap: 8px;*/
    color: var(--light-7);
  }
  .booking_accordion .promo-code-input .btn-main.guest_btn {
    padding: 0 16px;
    min-width: 76px;
    /* background-color: transparent !important;
    border: 2px solid var(--primary) !important;
    color: var(--light-5) !important; */
    height: 40px;
  }
  .custom-form .form-control.code,
  .custom-form .form-control,
  .booking_accordion .custom-form .login-popup .user-form input,
  .booking_accordion .custom-form .otp-popup .user-form input,
  .payment-page .promo-code-input .form-control,
  .booking_accordion .otp-input input {
    height: 40px;
  }

  /* .booking_accordion .promo-code-input .btn-main.guest_btn:hover,
      .booking_accordion .promo-code-input .btn-main.guest_btn:active,
        .booking_accordion .promo-code-input .btn-main.guest_btn.active{
          background-color: var(--primary) !important;
        } */
  .final-ticket .ticket-content .bottom .right {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }
  .final-ticket .ticket-content .bottom .right .ticket-btn {
    /* width: 170px; */
    margin-left: unset;
  }
  .grid-col-5 {
    grid-template-columns: 49% 49% !important;
    grid-gap: 2%;
  }

  /* .final-ticket .ticket-content .bottom .right .ticket-btn:not(:last-child) {
    margin-right: 16px;
  } */

  .back-header {
    margin-bottom: 24px;
  }

  .final-ticket .ticket-header {
    padding: 8px 16px;
  }

  .final-ticket .ticket-content {
    padding: 16px;
  }

  .final-ticket .ticket-content .top .heading {
    font-size: 20px;
  }

  .final-ticket .ticket-content .top .features p {
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .final-ticket .ticket-content .top .cinema-name {
    font-size: 12px;
    font-weight: 600;
  }

  .final-ticket .ticket-header {
    display: flex;
    /*flex-direction: column;*/
    justify-content: space-between;
    align-items: center;
  }

  .final-ticket .ticket-header h6 {
    /*margin-top: 6px;*/
    font-size: 14px;
  }

  .seat-type-popup .title-row .title {
    font-size: 14px;
    text-transform: capitalize;
  }

  .seat-type-popup .selected-seats {
    width: auto;
    height: auto;
    padding: 10px;
    background: var(--green-shade);
    border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Modelica Bold';
  }

  .final-ticket .ticket-content .bottom .left .content {
    font-size: 14px;
    padding-top: 0px;
  }

  .final-ticket .ticket-content .bottom .left .heading {
    font-size: 14px;
  }

  .payment-booking-details .middle .modifier-btn > span:nth-child(odd) {
    font-size: 16px;
    height: 20px;
    width: 20px;
    flex: 0 0 20px;
  }
}

@media (min-width: 1200px) {
  .final-ticket .ticket-content .bottom .left .grid-col-2 {
    display: grid;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: repeat(2, 50%);
    grid-column-gap: 50px;
  }

  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    /* display: grid;
    grid-template-columns: 20% auto 55%; */
    display: grid;
    grid-column-gap: 30px;
    /*grid-template-columns: 14% auto 58%;*/
    grid-template-columns: repeat(4, 1fr);
    background-color: var(--cinerji-Pantone-Blush);
    /* background-color: #f6f6f6; */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.42);
    padding: 20px 34px;
  }

  .final-ticket .ticket-content .bottom .left .grid-col-3 {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 50px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .final-ticket .ticket-content .bottom .left .grid-col-1,
  .final-ticket .ticket-content .bottom .left .grid-col-2 {
    display: grid;
    /*grid-template-columns: auto auto auto;*/
  }
  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    /* background-color: var(--dark-12); */
    background-color: #f6f6f6;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.42);
    padding: 34px;
    grid-template-columns: repeat(2, 1fr);
  }
  .booking_data_final {
    gap: 10px;
  }
  .special_final {
    white-space: pre-wrap;
  }
  .main_wrapper_final {
    display: block !important;
  }
  .final-ticket .ticket-content .bottom .left .grid-col-2 {
    grid-template-columns: 50% 50%;
  }
  .final-ticket .ticket-content .bottom .right {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
  }
}

/*******Mobile Search Bar**********/

.mobile-search-bar,
.mobile-sidebar {
  /* top: -3px; */
  top: 0;
  width: 100%;
  z-index: 9999;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  /* height: calc(120vh - 115px); */
  height: 100vh;
  /* background-color: var(--dark-1); */
  background-color: var(--cinerji-Pantone-Blush);
  /* border-top: 3px solid var(--primary); */
}

@media (min-width: 768px) {
  .mobile-search-bar,
  .mobile-sidebar {
    height: calc(120vh - 200px);
  }
}

@media (min-width: 1024px) {
  .mobile-search-bar,
  .mobile-sidebar {
    height: calc(120vh - 240px);
  }
}

.mobile-search-bar {
  left: 100%;
  -webkit-transition: left 0.5s ease-out;
  transition: left 0.5s ease-out;
}

.mobile-sidebar {
  left: 100%;
  -webkit-transition: left 0.5s ease-out;
  transition: left 0.5s ease-out;
  padding: 24px 0;
  color: var(--light-5);
}

.mobile-search-bar.is-active {
  left: 0;
}

.mobile-sidebar.is-active {
  left: 0;
}

.mobile-search-bar .search-back,
.mobile-sidebar .sidebar-close {
  cursor: pointer;
}

.mobile-sidebar .welcome-text {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Modelica Bold';

  margin: 30px auto;
  border-top: 1px dotted var(--light-5);
  border-bottom: 1px dotted var(--light-5);
  padding: 30px 0;
}

.mobile-sidebar .btn-main {
  height: 35px;
}

.mobile-sidebar .sidebar-links {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px dotted var(--light-5);
}

.mobile-sidebar p {
  transition: color 0.5s;
  cursor: pointer;
}

.mobile-sidebar p:active,
.mobile-sidebar p:focus {
  color: var(--primary);
}

.mobile-sidebar .sidebar-links p:not(:last-of-type) {
  margin-bottom: 16px;
}

.main-menu .search-btn,
.main-menu .hamburger-btn {
  cursor: pointer;
}

.mobile-search-bar .search-bar {
  background: rgba(47, 46, 49, 0.4);
  border: 1.5px solid var(--light-5);
  border-radius: 20px;
  font-size: 16px;
  height: 44px;
  width: 100%;
  padding: 0 24px;
  font-weight: 600;
  color: var(--light-5);
}

.mobile-search-bar .search-bar:focus {
  color: var(--light-5);
}

.mobile-search-bar .searched-movie img {
  width: 100%;
  height: 100%;
}

.mobile-search-bar .searched-movie-link {
  margin-bottom: 20px;
}

.mobile-search-bar aside {
  display: grid;
  grid-template-columns: 47% 47%;
  grid-column-gap: 6%;
  margin-top: 20px;
}

.mobile-search-bar .searched-movie {
  position: relative;
}

.mobile-search-bar .searched-movie .movie-content {
  position: absolute;
  bottom: 0;
  background: rgba(28, 27, 33, 0.85);
  width: 100%;
  color: var(--light-5);
  padding: 14px 12px 08px 12px;

  /* line-height: 28px; */
}

.mobile-search-bar .searched-movie .movie-label {
  position: absolute;
  top: 06px;
  right: 06px;
}

.mobile-search-bar .searched-movie .movie-label label {
  color: var(--light-5);

  height: 20px;
  font-size: 08px;
  width: 74px;
  /* text-align: center; */
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 06px;
}

.movie-label .btn-yellow {
  border: 1px solid var(--yellow-shade);
  background: var(--yellow-shade);
}

.movie-label .btn-green {
  background: var(--green-shade);
  border: 1px solid var(--green-shade);
}

.movie-label .btn-red {
  background: var(--primary);
  border: 1px solid var(--primary);
}

.mobile-search-bar .searched-movie .movie-content .movie-name {
  font-family: 'Modelica light';
  font-weight: 600;
  font-family: 'Modelica light';
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 08px;
  letter-spacing: 0.5px;
}

.mobile-search-bar .searched-movie .movie-content .genre,
.mobile-search-bar .searched-movie .movie-content .experiences {
  font-size: 11px;
  padding-bottom: 06px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile-bottom-menu {
  background: var(--dark-2);
  /* padding: 30px 0; */
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0px;
  z-index: 999;
}

.mobile-bottom-menu .menu-icon img {
  width: 35px;
  height: 25px;
}

.mobile-bottom-menu .menu-icon svg {
  width: 35px;
  height: 25px;
}

.mobile-bottom-menu .menu-icon:active svg > path,
.mobile-bottom-menu .menu-icon.active svg > path {
  fill: var(--primary);
}

.mobile-bottom-menu .menu-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.5s;
}

.mobile-bottom-menu .menu-icon:active {
  transform: scale(0.8);
}

/* Toast Container overrides start */

.Toastify__toast {
  border-radius: 8px !important;
  font-family: 'Modelica' !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  text-align: center;
}

.Toastify__toast-container--top-left {
  top: 80px;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 80px;
  right: 1em;
}

.Toastify__toast--dark {
  background: var(--dark-2) !important;
  color: var(--light-5);
}

.Toastify__toast--default {
  background: var(--light-5) !important;
  color: var(--light-1);
}

.Toastify__toast--info {
  background: var(--blue-shade) !important;
}

.Toastify__toast--success {
  background: var(--green-shade) !important;
}

.Toastify__toast--warning {
  background: var(--yellow-shade) !important;
}

.Toastify__toast--error {
  /* background: var(--primary) !important; */
  background: var(--blue) !important;
}

.Toastify__progress-bar--default {
  background: linear-gradient(
    to right,
    var(--green-shade),
    #5ac8fa,
    #007aff,
    var(--blue-shade),
    #5856d6,
    var(--primary)
  ) !important;
}

.Toastify__progress-bar--dark {
  background: var(--primary) !important;
}

.Toastify__close-button {
  opacity: 1 !important;
}

.btnDisabled {
  cursor: not-allowed !important;
  background-color: rgba(153, 152, 152, 0.678) !important;
  color: black !important;
  border: none !important;
}

.fs-16 {
  font-size: 16px;
}

.legal_text {
  font-size: 12px;
  font-weight: 100;
  background-color: black;
  padding: 11px;
  color: white;
  border-left: 1px solid #d4af46;
  border-radius: 0 0 6px 6px;
}

.legal_text_mobile {
  border-radius: 0 0 16px 16px;
  margin: -8px;
  border: none;
  margin-top: 3px;
}

/* Toast Container overrides end */

.movie-header {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  position: relative;
}
.movie-header .movie-sub-header {
  /* position: absolute; */
  /* top: 15%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.movie-header .header-details {
  display: flex;
  align-items: center;
  text-align: center;
}

.movie-details {
  position: relative;
}

.movie-header .rate {
  background: #d4307d;
  display: inline-block;
  padding: 05px 15px;
  font-size: 14px;
}
.movie-header .lang {
  background: #2f2e36;
  padding: 05px 15px;
  margin-left: 10px;
  font-size: 14px;
}
.movie-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(23, 22, 29, 0.85);
}
.movie-header .title {
  font-size: 22px;
}
.l-title {
  color: #969696;
  padding-bottom: 05px;
  font-size: 14px;
}
.l-border {
  border-top: 1px solid rgba(85, 85, 88, 0.5);
  border-bottom: 1px solid rgba(85, 85, 88, 0.5);
}
.p-title {
  color: #d4307d;
  text-transform: uppercase;
  font-size: 14px;
}
.h-vertical {
  border-top: 1px solid #969696;
  width: 22px;
  margin-top: 0;
  margin-bottom: 0;
  transform: rotate(90deg);
}
.movie-bottom {
  background-color: #d4307d;
  position: relative;
  font-size: 14px;
  /* background:#d4307d url("../pictures/svgs/circles.svg") no-repeat top; */
}
.movie-bottom .img-top {
  position: absolute;
  top: -8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.movie-bottom .img-top img {
  width: 100%;
}

.booking_accordion .custom-form label {
  font-size: 15px;
  font-weight: 700;
  font-family: 'SansSemiBold';
}
@media screen and (min-width: 100px) and (max-width: 600px) {
  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    background-color: #f6f6f6;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 42%);
    padding: 20px;
  }
  .ticket_bottom {
    padding: 20px !important;
  }
  .payment_details_new .payment_details_rows > section {
    grid-template-columns: repeat(2, 1fr);
  }

  .payment-booking-details .middle .checkbox-row > p {
    font-size: 12px;
  }
  .seat-type-popup .payment-booking-details .header {
    grid-template-columns: repeat(2, 1fr);
  }
  .all_btn_pop {
    padding: 10px;
  }
  .seat-type-popup .seat-type-cat {
    font-size: 14px;
    padding: 5px 20px;
  }
  .text_wrap_mob {
    white-space: nowrap;
  }
  .custom-check {
    margin-bottom: 0px;
  }
  .pop_hori_div {
    width: 80vw;
    display: none;
  }
  .pop_up_hori {
    display: none;
    width: 80vw;
  }
  .private_popup_btn {
    font-size: 10px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .seat-type-popup .answer {
    margin-bottom: 10px;
  }
  .payment-booking-details .header .movie-img {
    width: 90%;
  }
  .payment-booking-details .header {
    padding: 40px 10px 20px 10px;
    grid-column-gap: 18px;
  }
  .private_pop_spacing {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .total_pop {
    font-size: 12px;
  }
  .pop_total {
    font-size: 12px;
  }
  .total_para_pop {
    font-size: 8px;
  }
  .payment-booking-details .header .title {
    font-size: 12px;
  }
  .middle_private_pop {
    font-size: 12px;
    padding: 10px;
  }
  .add_private_pop {
    font-size: 12px;
    padding: 10px;
  }
  .payment-booking-details .middle .checkbox-row {
    margin: 0;
  }
  .payment-booking-details .footer {
    font-size: 12px;
    padding: 10px;
  }
  .final_heading_private {
    font-size: 10px;
  }
  .last_sec_pop {
    padding: 10px;
  }
  .qr_final {
    display: flex;
  }
  .final_heading_mob {
    display: flex;
    justify-content: center;
  }
  .final_para_mob {
    display: flex;
    justify-content: center;
  }
  .qr_para {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-family: 'SansMedium';
    color: black;
  }
  .ticket_text {
    margin-top: 30px;
  }
  .special_final_mob {
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    margin-top: 10px;
  }
  .grid_mob_space {
    margin-top: 30px;
  }
  .heading_mob {
    /* color: #fff !important; */
    /* text-shadow: 2px 2px 5px black; */
    /* height: 35px; */
    padding-bottom: 5px;
  }
  .heading_mob_down {
    font-size: 11px !important;
  }
  .grid-col-1-mob {
    /*padding: 20px;*/
    display: grid;
    /* background-color: var(--dark-12); */
    width: 100vw;
  }

  .mob-gri {
    padding: 20px;
  }
  .final-ticket .ticket-content .bottom .left .content {
    font-size: 12px;
  }
  .content_mob {
    display: flex;
  }
  /*.final-ticket .ticket-content .bottom .left .grid-col-2{
    display: grid;
    grid-template-columns: 100%;
  }*/
  .booking_grid_align {
    grid-column: span 2;
  }
  .tick_mob {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .ticket-btn {
    width: 80vw;
    margin-top: 0px !important;

    /* margin-left: 0px; */
  }
  .ticket-btn-gold {
    width: 80vw;
    margin-left: 0px;
  }
  .final_btn_main {
    /* margin-left: 0px; */
    margin-top: 0px !important;
    width: 80vw;
  }
  .final_btn_main_golden {
    width: 80vw;
  }
  .Inc_final {
    font-size: 10px;
  }
  .svg_ticket {
    width: 20px;
    margin-top: 0px;
  }
  .back_img_gold {
    background-size: 103px;
    background-position-y: TOP;
    background-position-x: center;
  }
  .seat-type-popup .seat-picker .seat-picker-row.headings {
    padding: 10px 30px;
  }
  .modi_mob {
    display: grid;
    justify-content: center;
  }
  .qr_sec {
    height: 250px;
  }
}
@media screen and (min-width: 760px) and (max-width: 850px) {
  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    /* grid-template-columns: 14% auto 58%; */

    background-color: var(--dark-12);
    box-shadow: 0px 15px 20px rgb(0 0 0 / 42%);
    padding: 20px 34px;
  }
  .pop_hori_div {
    width: 70vw;
    display: none;
  }
  .pop_up_hori {
    width: 70vw;
    display: none;
  }
  .ticket-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    flex-wrap: wrap;
    margin: 20px auto;
  }
  .ticket-btn-gold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    flex-wrap: wrap;
    /*margin: 20px auto;*/
  }
  .final_btn_main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    flex-wrap: wrap;
    margin: auto;
  }
}
@media screen and (min-width: 601px) and (max-width: 991px) {
  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    display: grid;
    grid-column-gap: 30px;
    /* grid-template-columns: 14% auto 58%; */
    grid-template-columns: repeat(4, 1fr);
    background-color: #f6f6f6;
    box-shadow: 0px 15px 20px rgb(0 0 0 / 42%);
    padding: 20px 34px;
  }
  .ticket-btn {
    width: 60vw;
  }
  .final_btn_main {
    width: 60vw;
  }
  .ticket-btn-gold {
    width: 60vw;
  }
  .normal {
    justify-content: center;
    display: grid;
    margin-top: 20px;
  }
}
@media screen and (max-width: 350px) {
  .show-details-popup .btn-main-group .tile:not(:last-of-type) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .show-details-popup .btn-main-group {
    justify-content: space-around;
  }
  .final-ticket .ticket-content .bottom .left .grid-col-1 {
    grid-gap: 10% !important;
  }
}
